a {
  display: block;
}
.title {
  font-size: 0.4rem;
}
.nameButton {
  width: 100px;
  text-align: center;
  padding: 10px 20px;
  background-color: blue;
  color: #fff;
}
